// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Store & Actions
import { useSelector, useDispatch } from "react-redux";
import { setUserStatus } from "store/modules/global";
import { setUserOnline } from "store/modules/global";
import { setUserStatus as setUserProfileStatus } from "store/modules/user";

// ** Custom Components
import Text from "components/typography/Text";
import OnlineCircle from "components/user/OnlineCircle";
import Block from "components/layout/Block";

// ** Styled Components
import { StyledButton } from "../styles";

// #####################################################

const ChangeStatusContent = ({ online, profileStatus, statusArray, close }) => {
	const { t } = useTranslation(["common"]);

	const dispatch = useDispatch();

	const globalUserId = useSelector((state) => state.global.user.id);
	const currentProfileUserId = useSelector((state) => state.user.main.id);

	const handleSetStatus = (status) => {
		dispatch(setUserStatus(status)).then(() => {
			if (globalUserId === currentProfileUserId) {
				dispatch(setUserProfileStatus(status));
			}
		});
	};

	// #####################################################

	return (
		<Block flex column pt={2} pb={2}>
			{statusArray.map(({ status, name }) => (
				<StyledButton
					onClick={() => {
						if (online === 0 || online === 1) {
							dispatch(setUserOnline(2));
						}
						handleSetStatus(status);
						close();
					}}
					key={status}
				>
					<OnlineCircle
						online={online}
						status={status}
						size="xsmall"
						style={{ marginBottom: "1px" }}
						isCurrentLoggedUser
					/>
					<Text ml={1} bold={status === profileStatus} size="0.9rem">
						{t(`common:status.${name}`)}
					</Text>
				</StyledButton>
			))}
		</Block>
	);
};

export default ChangeStatusContent;

// #####################################################
