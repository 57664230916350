// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Button from "components/base/Button";

// #####################################################

export const StyledButton = styled(Button)`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	&:hover {
		background-color: ${({ theme: { colors } }) => colors.menuItemHover};
	}

	&:active {
		background-color: ${({ theme: { colors } }) => colors.menuItemActive};
	}
`;

// #####################################################
