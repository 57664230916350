/* eslint-disable max-len */

const routes = {
	userpanelData: {
		key: "userpanelData",
		path: "/userpanel/data",
		link: "/userpanel/data",
		meta: { navIndex: 0, hideResearch: true },
	},

	userpanelGallery: {
		key: "userpanelGallery",
		path: "/userpanel/gallery",
		link: "/userpanel/gallery",
		meta: { navIndex: 1, hideResearch: true },
	},

	userpanelMessages: {
		key: "userpanelMessages",
		path: "/userpanel/messages",
		link: "/userpanel/messages",
		meta: {
			menuPillStatKey: "messages",
			navIndex: 2,
			red: true,
			hideResearch: true,
		},
	},

	userpanelNotifications: {
		key: "userpanelNotifications",
		path: "/userpanel/notifications",
		link: "/userpanel/notifications",
		meta: {
			menuPillStatKey: "notifications",
			navIndex: 3,
			red: true,
			hideResearch: true,
		},
	},

	userpanelLikes: {
		key: "userpanelLikes",
		path: "/userpanel/like",
		link: "/userpanel/like",
		meta: {
			menuPillStatKey: "likes",
			navIndex: 5,
			hideResearch: true,
		},
	},

	userpanelComments: {
		key: "userpanelComments",
		path: "/userpanel/comments",
		link: "/userpanel/comments",
		meta: {
			menuPillStatKey: "comments",
			navIndex: 6,
			hideResearch: true,
		},
	},

	userpanelFriends: {
		key: "userpanelFriends",
		path: "/userpanel/friends",
		link: "/userpanel/friends",
		meta: {
			wrap: true,
			menuPillStatKey: "friends",
			navIndex: 7,
			hideResearch: true,
		},
	},

	userpanelFollowing: {
		key: "userpanelFriendsFollowing",
		path: "/userpanel/following",
		link: "/userpanel/following",
		meta: {
			wrap: true,
			menuPillStatKey: "following",
			navIndex: 8,
			hideResearch: true,
		},
	},

	userpanelVisitors: {
		key: "userpanelVisitors",
		path: "/userpanel/visitors",
		link: "/userpanel/visitors",
		meta: { menuPillStatKey: "visitors", navIndex: 9, hideResearch: true },
	},

	userpanelPayments: {
		key: "userpanelPayments",
		path: "/userpanel/payments",
		link: "/userpanel/payments",
		meta: { navIndex: 10, menuIconStatKey: "payments", hideResearch: true },
	},

	userpanelSettings: {
		key: "userpanelSettings",
		path: "/userpanel/settings",
		link: "/userpanel/settings",
		meta: {
			navIndex: 11,
			suspended: true,
			hideResearch: true,
		},
	},
};

export default routes;

export const routesArray = Object.keys(routes).map((route) => ({
	...routes[route],
}));
