// ** React Imports
import { useHistory, matchPath } from "react-router-dom";

// ** Redux & Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/modules/auth";
import { setInitialState } from "store/reducer";

// ** Third Party Components
import { toast } from "helpers/toastWrapper";
import md5 from "md5";

// ** Utils & Helpers
import api from "helpers/ApiClient";

// ** Config
import configGlobal from "configuration";

// #####################################################

const useLogout = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const firebaseId = useSelector((state) => state.global.firebaseId);

	const isClient = useSelector((state) => state.global.isClient);

	const logoutFn = () => {
		const firebaseLocalStorageObject = JSON.parse(
			isClient
				? localStorage.getItem(
						configGlobal.firebase.localStorage.itemName
				  )
				: null
		);

		const firebaseMd5Hash = firebaseLocalStorageObject
			? md5(firebaseLocalStorageObject?.token)
			: undefined;

		return api
			.post(
				"/merge/bulk",
				JSON.stringify([
					{
						key: "unsubscribeFCM",
						method: "DELETE",
						url: "/setting/fcm",
						args: {
							id: firebaseId || undefined,
							hash: firebaseMd5Hash || undefined,
						},
						version: "1.2",
					},
					{
						key: "logout",
						method: "DELETE",
						url: "/authorization/logout",
						args: {
							id: firebaseId || undefined,
							hash: firebaseMd5Hash || undefined,
						},
						version: "1.1",
					},
				])
			)
			.then(() => {
				const isHome = matchPath(location?.pathname, {
					path: "/",
					exact: true,
				});

				dispatch(logout());
				dispatch(setInitialState());
				if (!isHome) {
					history.push("/");
				}
				window.scrollTo(0, 0);
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	return logoutFn;
};

export default useLogout;
