import { toast as reactToastifyToast } from "react-toastify";

const parseMessage = (message) => {
	let text = message ? message.trim() : "";

	if (
		text &&
		text.length > 0 &&
		/[a-zA-Z0-9]$/.test(text) &&
		!text.endsWith(".")
	) {
		text += ".";
	}

	return text;
};

export const toast = {
	success: (message) => {
		const parsedMessage = parseMessage(message);
		return reactToastifyToast.success(parsedMessage);
	},
	error: (message) => {
		const parsedMessage = parseMessage(message);
		return reactToastifyToast.error(parsedMessage);
	},
};
