// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Store & Actions
import { useSelector } from "react-redux";

// ** Custom Components
import Button from "components/base/Button";
import Dropdown from "components/layout/Dropdown";
import Text from "components/typography/Text";
import OnlineCircle from "components/user/OnlineCircle";
import Icon from "components/global/Icon";

// ** Change Status Component
import ChangeStatusContent from "./components/ChangeStatusContent";

// ** Custom Hooks
import useOpenClose from "hooks/useOpenClose";

// ** SVG Static Imports
import { ReactComponent as SolidCaretUp } from "resources/icons/solid/caret-up.svg";
import { ReactComponent as SolidCaretDown } from "resources/icons/solid/caret-down.svg";
import { DropdownTriggerContext } from "components/layout/Dropdown/DropdownTriggerContext";

// ** Styled Components
import { borderRadiuses } from "styles/declares";

// #####################################################

const STATUS_ARRAY = [
	{ status: 1, name: "online" },
	{ status: 2, name: "meet" },
	{ status: 3, name: "invisiable" },
	{ status: 4, name: "invisible" },
];

// #####################################################

const ChangeStatus = () => {
	const { isOpen, close, toggle } = useOpenClose();

	const { t } = useTranslation(["common"]);
	const online = useSelector((state) => state.global.user.online);
	const profileStatus = useSelector(
		(state) => state.global.user.profileStatus
	);

	const useLayerOptions = {
		overflowContainer: true,
		auto: true,
		possiblePlacements: ["bottom-start", "top-start"],
		triggerOffset: 5,
	};

	const customLayerStyle = {
		zIndex: 2500,
		borderRadius: `${borderRadiuses.card}rem`,
		borderTopRightRadius: `${borderRadiuses.card}rem`,
		borderTopLeftRadius: `${borderRadiuses.card}rem`,
	};

	// #####################################################

	return (
		<Dropdown
			isOpen={isOpen}
			close={close}
			useLayerOptions={useLayerOptions}
			customLayerStyle={customLayerStyle}
			withMobileVersion={true}
			mobileTitle={t("common:status:title")}
			customMobileLayerStyle={{
				left: "2rem",
				right: "2rem",
			}}
			minWidth
			menuContent={() => (
				<ChangeStatusContent
					online={online}
					profileStatus={profileStatus}
					statusArray={STATUS_ARRAY}
					close={close}
				/>
			)}
		>
			<DropdownTriggerContext.Consumer>
				{({ triggerProps }) => (
					<Button
						onClick={toggle}
						innerRef={triggerProps.ref}
						style={{
							display: "flex",
							alignItems: "center",
							padding: 0,
							marginLeft: "-1px",
						}}
					>
						<OnlineCircle
							isCurrentLoggedUser
							online={online}
							status={profileStatus}
							size="xsmall"
							style={{ marginBottom: "2px" }}
						/>
						<Text size="0.9rem" ml={1} mr={2}>
							{t(
								`common:status.${
									STATUS_ARRAY.find(
										({ status }) => status === profileStatus
									)?.name ?? null
								}`
							)}
						</Text>
						<Icon
							svgIconComponent={
								isOpen ? SolidCaretUp : SolidCaretDown
							}
						>
							{isOpen ? "caret-up" : "caret-down"}
						</Icon>
					</Button>
				)}
			</DropdownTriggerContext.Consumer>
		</Dropdown>
	);
};

export default ChangeStatus;

// #####################################################
